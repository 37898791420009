import React from 'react';
import { motion } from 'framer-motion';
import { Scissors, Clock, Users2, Image, Store, User, Calendar, Search, Star, Sparkles } from 'lucide-react';

const BeautifulLoadingScreen = ({ language = 'en', userType }) => {
    const isCustomer = userType === 'customer';

    // Shop owner translations
    const shopOwnerTranslations = {
        en: {
            loading: "Loading your barbershop",
            preparing: "Preparing your experience",
            wait: "Just a moment"
        },
        tr: {
            loading: "Berber dükkanınız yükleniyor",
            preparing: "Deneyiminiz hazırlanıyor",
            wait: "Bir saniye lütfen"
        },
        ar: {
            loading: "جاري تحميل صالون الحلاقة",
            preparing: "جاري تحضير تجربتك",
            wait: "لحظة من فضلك"
        },
        de: {
            loading: "Ihr Friseursalon wird geladen",
            preparing: "Wir bereiten Ihr Erlebnis vor",
            wait: "Einen Moment bitte"
        }
    };

    // Customer translations
    const customerTranslations = {
        en: {
            loading: "Loading your account",
            preparing: "Preparing your appointments",
            wait: "Just a moment"
        },
        tr: {
            loading: "Hesabınız yükleniyor",
            preparing: "Randevularınız hazırlanıyor",
            wait: "Bir saniye lütfen"
        },
        ar: {
            loading: "جاري تحميل حسابك",
            preparing: "جاري تحضير مواعيدك",
            wait: "لحظة من فضلك"
        },
        de: {
            loading: "Ihr Konto wird geladen",
            preparing: "Ihre Termine werden vorbereitet",
            wait: "Einen Moment bitte"
        }
    };

    const t = isCustomer
        ? (customerTranslations[language] || customerTranslations.en)
        : (shopOwnerTranslations[language] || shopOwnerTranslations.en);

    const containerVariants = {
        animate: {
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    // Shop owner icon variants
    const shopOwnerIconVariants = {
        initial: { scale: 0, rotate: -30, opacity: 0 },
        animate: {
            scale: 1,
            rotate: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 260,
                damping: 20,
                duration: 0.6
            }
        }
    };

    // Customer icon variants
    const customerIconVariants = {
        initial: { scale: 0, rotate: -10, opacity: 0 },
        animate: {
            scale: 1,
            rotate: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 260,
                damping: 20,
                duration: 0.6
            }
        }
    };

    const textVariants = {
        initial: { opacity: 0, y: 10 },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    const progressVariants = {
        initial: { width: "0%" },
        animate: {
            width: "100%",
            transition: {
                duration: 2.5,
                ease: "easeInOut",
                repeat: Infinity
            }
        }
    };

    // Customer specific variants
    const avatarVariants = {
        initial: { scale: 0.6, opacity: 0 },
        animate: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    const sparkleVariants = {
        initial: { scale: 0, opacity: 0 },
        animate: {
            scale: [0, 1.2, 1],
            opacity: [0, 1, 0.8],
            transition: {
                duration: 1.5,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
                delay: 0.3
            }
        }
    };

    // Shop owner icons
    const shopOwnerIcons = [
        { Icon: Store, color: "text-primary" },
        { Icon: Scissors, color: "text-secondary" },
        { Icon: Clock, color: "text-accent" },
        { Icon: Users2, color: "text-primary" },
        { Icon: Image, color: "text-secondary" }
    ];

    // Customer icons
    const customerIcons = [
        { Icon: Calendar, color: "text-secondary" },
        { Icon: Search, color: "text-accent" },
        { Icon: Star, color: "text-warning" }
    ];

    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-base-100 bg-opacity-95 z-50">
            <div className="w-full max-w-sm px-4">
                {isCustomer ? (
                    // Customer UI
                    <>
                        {/* Avatar animation */}
                        <motion.div
                            initial="initial"
                            animate="animate"
                            className="flex justify-center mb-8 relative"
                        >
                            <motion.div
                                variants={avatarVariants}
                                className="relative"
                            >
                                <div className="w-24 h-24 rounded-full bg-gradient-to-br from-primary to-secondary flex items-center justify-center shadow-lg">
                                    <User className="w-12 h-12 text-primary-content" />
                                </div>

                                {/* Decorative sparkles */}
                                {[...Array(3)].map((_, i) => (
                                    <motion.div
                                        key={i}
                                        variants={sparkleVariants}
                                        className="absolute"
                                        style={{
                                            top: `${-10 + (i * 20)}%`,
                                            left: `${80 - (i * 40)}%`,
                                            transformOrigin: 'center',
                                        }}
                                    >
                                        <Sparkles className={`w-6 h-6 text-primary opacity-80`} />
                                    </motion.div>
                                ))}
                            </motion.div>
                        </motion.div>

                        {/* Icons animation */}
                        <motion.div
                            className="flex justify-center gap-6 mb-8"
                            variants={containerVariants}
                            initial="initial"
                            animate="animate"
                        >
                            {customerIcons.map(({ Icon, color }, index) => (
                                <motion.div
                                    key={index}
                                    variants={customerIconVariants}
                                    className={`w-12 h-12 flex items-center justify-center rounded-full bg-base-200 ${color} shadow-md`}
                                >
                                    <Icon className="w-6 h-6" />
                                </motion.div>
                            ))}
                        </motion.div>
                    </>
                ) : (
                    // Shop owner UI
                    <>
                        {/* Logo/branding placeholder */}
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            className="flex justify-center mb-8"
                        >
                            <div className="flex items-center gap-2">
                                <Scissors className="w-8 h-8 text-primary" />
                                <span className="text-2xl font-bold text-base-content">Barbers Buddies</span>
                            </div>
                        </motion.div>

                        {/* Icons animation */}
                        <motion.div
                            className="flex justify-center gap-4 mb-8"
                            variants={containerVariants}
                            initial="initial"
                            animate="animate"
                        >
                            {shopOwnerIcons.map(({ Icon, color }, index) => (
                                <motion.div
                                    key={index}
                                    variants={shopOwnerIconVariants}
                                    className={`w-10 h-10 flex items-center justify-center rounded-full bg-base-200 ${color}`}
                                >
                                    <Icon className="w-5 h-5" />
                                </motion.div>
                            ))}
                        </motion.div>
                    </>
                )}

                {/* Loading text */}
                <motion.div
                    className="text-center mb-6"
                    variants={textVariants}
                    initial="initial"
                    animate="animate"
                >
                    <h3 className="text-xl font-semibold text-base-content mb-2">{t.loading}</h3>
                    <p className="text-base-content/70">{t.preparing}</p>
                </motion.div>

                {/* Loading bar */}
                <div className="w-full h-2 bg-base-200 rounded-full overflow-hidden mb-4">
                    <motion.div
                        className={`h-full bg-gradient-to-r ${isCustomer
                            ? "from-secondary via-primary to-secondary"
                            : "from-primary via-secondary to-primary"}`}
                        variants={progressVariants}
                        initial="initial"
                        animate="animate"
                    />
                </div>

                {/* Waiting text */}
                <motion.p
                    className="text-sm text-center text-base-content/50"
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: [0.5, 1, 0.5],
                        transition: {
                            duration: 2,
                            repeat: Infinity
                        }
                    }}
                >
                    {t.wait}
                </motion.p>
            </div>
        </div>
    );
};

export default BeautifulLoadingScreen;